import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Wrapper from "./components/Wrapper";
import Home from './screens/Home';
import Projects from './screens/Projects';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
        <Route path="/" element={<Wrapper/>}>
          <Route path="/"  element={<Home/>} index />
          <Route path="projects" element={<Projects/>} />
        </Route>
        </Routes>
    </Router>
 
    </div>
  );
}

export default App;
