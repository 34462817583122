import React from "react";
import styled from "styled-components";
import Contact from "./Contact";

const Footer = () => {
  return (
    <Container>
      <Contact/>
      <div className="bottom">
      <p> © all rights reserved. Oyindamola dawodu 2023</p>
      </div>
    </Container>
  );
};

const Container = styled.footer`
.bottom{
  background-color: #A3776B;
  color: white;
  font-size: 12px;
  text-align: center;
  padding: 18px 0;
  text-transform: uppercase;
}
`;
export default Footer;
