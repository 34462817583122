import React from "react";
import styled from "styled-components";
import Oyinda from "../../assets/bio-pic.png";

const About = () => {
  return (
    <Container id="about">
      <h2>About</h2>
      <div className="flexbox">
        <div className="img__container">
          <img src={Oyinda} alt="Oyinda" />
        </div>
        <article>
          <header>I am a Software Engineer ​and a Community Builder</header>
          <p>
            deeply committed to advancing open-source technologies and
            ​fostering their widespread adoption.
          </p>

          <p>
          As a Frontend Engineer, I specialize in crafting engaging interfaces with <strong>React.js, TypeScript and Next.js</strong>, as well as implementing responsive design principles to ensure optimal user experiences across various devices while <strong>ensuring robust security measures are integrated to safeguard user data and enhance the overall system security</strong>, guaranteeing a secure and enjoyable interaction for all users.
          </p>

          <p>
            As a Community Manager for tech-focused communities, I excel at uniting ​professionals and enthusiasts through engaging tech events, ​workshops and meetups.
          </p>

          <p>
            Besides coding and community engagement, <strong>I specialize in ​creating
            clear and concise documentation</strong>, effectively bridging ​technical and
            non-technical understanding and ​communicating a product or tool's
            value.
          </p>
        </article>
      </div>
    </Container>
  );
};

const Container = styled.section`
  background-color: #fbe6e0;
  padding: 70px 24px;

  h2 {
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 18px;
    text-transform: uppercase;
    margin-bottom: 20px;
    margin-top: 40px;
  }
  p {
    font-size: 14px;
    font-family: "Montserrat";
    line-height: 30px;
  }

  p + p {
    margin-top: 20px;
  }

  header {
    font-family: "medium";
    font-size: 24px;
    line-height: 25px;
    font-weight: 600;
    margin-bottom: 12px;
    margin-top: 14px;
  }

  img {
    width: 100%;
    max-height: 450px;
    object-fit: cover;
  }

  @media (min-width: 768px) {
    padding-bottom: 100px;

    .flexbox {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10vw;
      margin-top: 60px;

      article {
        width: 50%;
        max-width: 515px;
        font-family: "Montserrat";
      }

      header {
        font-family: "medium";
        font-size: 35px;
        line-height: 40px;
        font-weight: 600;
        margin-bottom: 12px;
        margin-top: 14px;
      }

      p {
        font-size: 16px;
      }
    }
    h2 {
      margin-left: 7vw;
    }
  }
`;

export default About;
