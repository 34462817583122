import React from "react";
import QuoteBG from "../../assets/quote.png";
import styled from "styled-components";

const Quote = () => {
  return (
    <Container>
      <div className="box">
        I Help Build Strong Communities,<br/> Advocate Open-Source Tech and <br/> Simplify
        Technical Concepts.
      </div>
      <img src={QuoteBG} alt="quote" />
    </Container>
  );
};

const Container = styled.section`
padding: 24px;
  img {
    width: 100%;
    object-fit: cover;
    margin-top: 20px;
  }

  .box{
    color:#473433;
    font-size: 29px;
    font-family: medium;
    font-weight: 600;
    text-align: center;
    line-height: 48px;
    padding: 40px 10px;
    /* height: 300px; */
    background-color: #FFE6E0;
    border: 3px solid #473433 ;
  }

  @media (min-width: 768px) {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 120px 0;

    .box{
        max-width: 400px;
        height: 380px;
        font-size: 34px;
        padding: auto 40px;
    }

    img{
        max-width: 400px;
        margin-top: 0;
    }
  }
`;

export default Quote;
