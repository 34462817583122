import React from "react";
import styled from "styled-components";
import BG from "../../assets/about-bg.jpeg";

const Skills = () => {
  return (
    <Container>
      <div className="set">
        <h3>My Tools</h3>
        <ul>
          <li>JavaScript: ReactJs, Next Js, Node Js and Express Js</li>
          <li>TypeScript</li>
          <li>MongoDB</li>
          <li>Styled components</li>
          <li>Sass</li>
          <li>Wordpress</li>
          <li>Shopify</li>
          <li>Git &amp; Github</li>
          <li>Markdown</li>
          <li>Notion</li>
        </ul>
      </div>

      <div className="set set2">
        <h3>My Expertise</h3>
        <ul>
          <li>Software Development</li>
          <li>Open Source Advocacy</li>
          <li>Technical Writing</li>
          <li>Community Building</li>
          <li>Public Speaking</li>
          <li>Event Hosting and Organization</li>
        </ul>
      </div>

      <img src={BG} alt="grid background" />
    </Container>
  );
};

const Container = styled.section`
  padding: 24px;
  padding-left: 40px;
  h3 {
    font-size: 24px;
    font-family: medium;
    font-weight: 600;
    margin-bottom: 10px;
  }
  ul li {
    font-size: 14px;
    width: 230px;
    line-height: 28px;
    font-family: "Montserrat";
  }

  .set {
    padding-left: 50px;
    /* display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center; */
  }

  .set + .set {
    margin-top: 20px;
  }

  img {
    width: 100%;
    object-fit: cover;
    margin-top: 20px;
    max-height: 350px;
  }

  @media (min-width: 768px) {
    padding-top: 60px;
    padding-left: 10vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .set {
      align-self: flex-start;
      h3 {
        font-size: 35px;
      }
      ul li {
        font-size: 16px;
        line-height: 37px;
        max-width: 230px;
      }
    }

    .set2{
        margin-top: -5px !important;
    }

    img {
      width: 150px;
      /* height: 280px; */
      align-self: baseline;
      margin-top: 200px;
    }
  }

  @media (min-width: 950px) {
    img {
      width: 350px;
      /* height: 280px; */
      align-self: baseline;
      margin-top: 200px;
    }
  }
`;

export default Skills;
