import React from "react";
import BG from "../../assets/my-bg.jpeg";
import styled from "styled-components";
import { TypeAnimation } from 'react-type-animation';



const Herosection = () => {
  return (
    <StyledContainer>
      <div className="details">
        {/* <p className="portfolio">portfolio</p> */}
        <div className="my__bio">

        <div className="name">
          <h1>
            <span>Oyin </span>
            <span>Dawodu</span>
          </h1>
        </div>
        <TypeAnimation
        className="title"
        sequence={[
          "The Software Engineer",
          1000,
          "The Community Builder",
          1000,
          "The Open Source Advocate",
          1000,
        ]}
        speed={50}
        // style={{ fontSize: '2em' }}
        repeat={Infinity}
        />
        {/* <p className="title">The Eccentric developer</p> */}
        </div>

        <div className="email">
          <div className="box">
            <a href="mailto:oyindamoladawodu22@gmail.com" target="_blank" rel="noreferrer" >Email Me</a>
            <div className="line"></div>
          </div>
        </div>
      </div>
      <img src={BG} alt="background" />
    </StyledContainer>
  );
};

const StyledContainer = styled.header`
  background: var(--mainBrown);
  color: #f7f7f4;
  /* max-width: 1800px; */

  img {
    height: 400px;
    width: 100%;
    object-fit: cover;
  }

  .details {
    height: 100%;
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    padding: 8rem 3rem;
    justify-content: center;

    /* .portfolio {
      margin-bottom: 7rem;
      text-transform: uppercase;
      letter-spacing: 0.09em;
      line-height: 1.4em;
      justify-self: flex-start;
    } */

    h1 span {
      font-family: medium;
      line-height: 1.02em;
      font-size: 6rem;
      text-align: center;
    }

    .title {
      text-transform: uppercase;
      letter-spacing: 0.09em;
      line-height: 1.4em;
      margin-top: 20px;
    }

    .email {
      width: 100%;
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: flex-end;
      margin-top: 8rem;
      transition: all 0.2s ease-in-out;
      a {
        text-transform: uppercase;
        text-decoration: none;
        color: white;
        letter-spacing: 0.09em;
        line-height: 1.4em;
      }
      .line {
        height: 2px;
        width: 110px;
        display: block;
        text-align: center;
        border-bottom: 1px solid white;
      }
      :hover{
        color: white;
        font-weight: 600;
        .line{
          border-bottom: 2px solid white;
        }
      }
    }
  }
  span {
    display: block;
  }

  @media (max-width: 400px) {
    h1 span {
      font-size: 4rem !important;
    }
    /* .portfolio {
      margin-bottom: 8rem;
    } */
    .email {
      margin-top: 9rem !important;
    }
  }

  @media (min-width: 760px) {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    /* align-items: center; */
    height: 90vh;
    max-height: 900px;


    img {
      height: 100%;
      width: 45vw;
    
    }

    .my__bio{
      height: 500px;
      margin-top: 3rem;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
    
    
    .email{
      /* margin-top:12rem; */
      align-self: flex-end;
    }
    
    .details{
      padding: 5rem 3rem;
      width: 55vw;
    }
  }
`;

export default Herosection;
