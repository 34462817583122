import React from 'react'
import Header from './Header'
import Gallery from './Gallery'
import styled from "styled-components";

const index = () => {
  return (
    <Container>
    <Header/>
    <Gallery/>
    </Container>
  )
}

const Container = styled.main`
background-color:#FBE6E0;
padding-bottom: 250px;
`;

export default index