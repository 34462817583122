import React from "react";
import styled from "styled-components";
import SCA from "../../assets/scab.png";
import Microsoft from "../../assets/micro.jpeg";
import MKD from "../../assets/makurdi.png";
import ReactWK from "../../assets/react.png";
import Chimoney from "../../assets/chimoney.jpeg";
import WebDV from "../../assets/webdev.png";
import { AiOutlineDoubleRight } from "react-icons/ai";

const data = [
  {
    id: 1,
    title: "OSCAFEST Makurdi",
    img: MKD,
    url: "https://drive.google.com/file/d/1_Y-Ll59oxZULRELjX_j5f1oHwuTF3hYH/view",
  },
  {
    id: 2,
    title: "React JS Workshop",
    img: ReactWK,
    url: "https://www.youtube.com/watch?v=18Mv294nCts&t=1061s",
  },
  {
    id: 3,
    title: "Microsoft Global Hackathon",
    img: Microsoft,
    url: "https://www.linkedin.com/posts/oyin-dawodu_microsoft-microsoftgarage-product-activity-7109406014331850752-DKHw?utm_source=share&utm_medium=member_desktop",
  },
  {
    id: 4,
    title: "Developer API Challenge",
    img: Chimoney,
    url: "https://chimoney.io/blogs/unlocking-innovation-chimoney-developer-api-challenge-1",
  },
  {
    id: 5,
    title: "Web Development Workshop",
    img: WebDV,
    url: "https://www.youtube.com/watch?v=Mha3Df0RxdE&list=PLed5nrdPDtrXtYor1ktlJ80Oq4Vyptte6",
  },
  {
    id: 6,
    title: "She Code Africa Lagos",
    img: SCA,
    url: "https://www.linkedin.com/posts/oyin-dawodu_first-scalagosbonding-networking-activity-7051639152856997889-9FIh?utm_source=share&utm_medium=member_desktop",
  }
];

const Events = () => {
  return (
    <Container id="events">
      <div className="wrapper">
        <h3>Events and Workshops</h3>

        <div className="box">
          {data.map((item, index) => (
            <div
              className="gallery"
              key={index}
              style={{ backgroundImage: `url(${item.img})` }}
            >
              <a href={`${item.url}`} target="_blank" rel="noreferrer">
                <span className="topic">{item.title}</span>
              </a>
            </div>
          ))}
        </div>
        <footer>
          <AiOutlineDoubleRight />
        </footer>
      </div>
    </Container>
  );
};

const Container = styled.section`
  padding: 70px 44px;
  background-color: #fbe6e0;
  padding-bottom: 50px;
  overflow: hidden;

  h3 {
    margin-bottom: 30px;
    text-align: center;
    font-family: medium;
    font-size: 45px;
    line-height: 50px;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .box {
    position: relative;
    width: 100%;
    .gallery + .gallery {
      margin-top: 40px;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    .gallery {
      border-radius: 0.875rem;
      height: 370px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      cursor: pointer;

      a {
        text-decoration: none;
      }

      .topic {
        width: 100%;
        display: flex;
        align-items: flex-end;
        padding: 0 30px;
        padding-bottom: 20px;
        color: #fff;
        font-weight: 600;
        font-size: 18px;
        height: 100%;
        background: linear-gradient(
          rgba(0, 0, 0, 0) 68.21%,
          rgba(0, 0, 0, 0.7) 88.31%
        );
        cursor: pointer;
      }
    }
  }

  footer svg {
    visibility: hidden;
  }

  @media (min-width: 768px) {
    padding: 100px 70px;
    padding-bottom: 120px;
    h3 {
      line-height: 60px;
      margin-bottom: 40px !important;
      font-family: medium;
      font-size: 50px;
      margin-bottom: 40px;
    }

    .wrapper {
      display: block !important;
      position: relative;
    }

    .box {
      display: flex;
      align-items: center;
      margin-left: 8vw;
      gap: 20px;
      overflow-x: scroll !important;

      &::-webkit-scrollbar {
        display: none;
      }

      max-width: 1200px;
      gap: 15px;

      .gallery {
        min-width: 350px;
        height: 300px;
        /* margin: 20px; */
        display: block;
        background-size: cover;
        margin-top: 0 !important;

        &:hover {
          border: 2px solid #787575;
        }
      }
    }

    footer {
      position: absolute;
      bottom: -60px;
      right: 40px;
      margin-top: 40px;
      text-align: right;
      svg {
        visibility: visible;
        font-size: 36px;
        font-weight: 700;
      }
    }
  }
`;

export default Events;
