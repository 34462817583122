import React from "react";
import data from "./data";
import styled from "styled-components";

import { FiLink } from "react-icons/fi";
import { BiCodeAlt } from "react-icons/bi";

const Gallery = () => {
  return (
    <Container>
      <h2>My Projects</h2>
      <div className="project">
        {data.map(({ id, title, img, website, repo, tools, content }) => (
          <section key={id}>
            <div className="description">
              <h3>{title}</h3>
              <div
                className="content"
                dangerouslySetInnerHTML={{ __html: content }}
              ></div>
              <ul dangerouslySetInnerHTML={{ __html: tools }}></ul>

              <div className="links">
                {website ? (
                  <button className="btn-1">
                    <a href={website} target="_blank" rel="noreferrer">
                      Live Demo
                    </a>
                  </button>
                ) : (
                  ""
                )}
                {repo ? (
                  <button className="btn-2">
                    <a href={repo} target="_blank" rel="noreferrer">
                      Code
                    </a>
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="imgWrapper">
              <img src={img} alt="website screenshot" />
            </div>
          </section>
        ))}
      </div>
    </Container>
  );
};

const Container = styled.section`
  padding: 24px;

  h2 {
    margin-left: 6rem;
    font-size: 2rem;
  }

  .project {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  section {
    color: white;

    .description {
      background: var(--lightBrown);
      padding: 40px 70px;
      border-radius: 0.5rem;
      text-align: left;
      width: 100%;
      max-width: 700px;
      max-height: 500px;
      margin-top: 20px;

      p {
        margin: 15px 0;
        font-size: 13px;
        line-height: 20px;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
      }

      h3 {
        margin: 0;
        font-size: 1.3rem;
        font-weight: 600;

        a {
          color: white;
          font-family: Verdana, Geneva, Tahoma, sans-serif;
          text-decoration: none;
        }
        /* &:hover {
          opacity: 0.8;
        } */
      }

      ul {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 2rem 0;
        list-style: none;

        li {
          font-weight: 600;
          font-size: 14px;
        }

        li + li {
          margin-left: 1rem;
        }
      }

      .links {
        button {
          background-color: white;
          color: #2d3748;
          margin-left: 1rem;
          padding: 8px 15px;
          border-radius: 10px;
          border: 1px solid white;
          a {
            color: #2d3748;
            text-decoration: none;
            font-size: 14px;
            font-weight: 500;
          }
          &:hover {
            cursor: pointer;
            background-color: #FBE6E0
          }
        }
        .btn-2 {
          background-color: transparent;

          a {
            color: white;
          }
          &:hover {
            color: white;
            cursor: pointer;
            background-color: #FBE6E0;
            a {
              color: #2d3748;
            }
          }
        }

        a + a {
          margin-left: 2rem;
        }
      }
    }

    .imgWrapper {
      max-width: 700px;
      max-height: 700px;
      margin-top: 20px;
      img {
        width: 100%;
        object-fit: cover;
        border-radius: 0.5rem;
      }
    }
    

    @media (min-width: 850px) {
      display: flex;
      align-items: center;
      justify-content: center;

      .description {
        padding: 3rem;
        padding-right: 6rem;
        .content {
          height: 15vh;
        }
        p {
          font-size: 14px;
        }
      }

      .imgWrapper {
        margin-left: -50px;
        
      }
    }

    @media (min-width: 1200px) {
      padding: 0 5rem;
      .description {
        padding: 4rem;
        padding-right: 6rem;
      }
    }
  }
`;

export default Gallery;
