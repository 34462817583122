import React from "react";
import styled from "styled-components";
import Standage from "../../assets/standage.png";
import Laptops from "../../assets/laptops.png";
import Coffee from "../../assets/coffee.png";
import ArtShop from "../../assets/artshop.png";
import { AiOutlineDoubleRight } from "react-icons/ai";

const data = [
  {
    id: 1,
    title: "Kenad and Standage",
    img: Standage,
    url: "https://standage.com.ng/",
    tools:
      "<li>React.js</li><li>Typescript</li><li>Styled Components</li><li>Wordpress API</li><li>Nodemailer</li>",
    details:
      "<p>A fully functional website for Kenad and Standage - an IT Solutions & International Trading company.</p>",
  },
  {
    id: 2,
    title: "Art Shop",
    img: ArtShop,
    url: "https://my-art-shop.vercel.app/",
    details:
      "<p>A website that helps users shop for beautiful wall posters, for their home or workspace.</p>",
    tools:
      "<li>Next.js</li><li>React.js</li><li>Styled Components</li><li>REST API</li>",
  },
  {
    id: 3,
    title: "Digitrad PC",
    img: Laptops,
    url: "https://digitradpc.com.ng/",
    tools: "<li>Shopify Themes</li><li>Flutterwave Payment Integration</li>",
    details:
      "<p>An E-commerce application that features  high quality pre-owned laptops</p><p>it has features such as Cart system, Shipping form, Payment integration</p>",
  },

  {
    id: 4,
    title: "Coffe Tip",
    img: Coffee,
    url: "https://coffeetip.vercel.app/",
    details:
      "<p>A platform that helps creators collect tips using chimoney payment API</p>",
    tools:
      "<li>React.js</li><li>Node Js</li><li> Chimoney API and Wallet integration</li>",
  },
 
];

const Projects = () => {
  return (
    <Container>
      <div className="flexbox">
        <h2>work</h2>
        <AiOutlineDoubleRight />
      </div>
      <header>
        {data.map((item, index) => (
          <div className="box" key={index}>
            <a href={`${item.url}`} target="_blank" rel="noopener noreferrer">
              <img src={item.img} alt="standage" />

              <div className="description">
                <div dangerouslySetInnerHTML={{ __html: item.details }}></div>
                <ul dangerouslySetInnerHTML={{ __html: item.tools }}></ul>
              </div>
            </a>
          </div>
        ))}
      </header>
      <div className="details">
        <h3>
          My Coding <br /> Projects
        </h3>
        <p>
          I'm an expert in converting Figma designs to live UI designs using
          <strong>React.js, TypeScript, Next.js, and Styled Components.</strong>
          I've also mastered API development with{" "}
          <strong>Node.js, Express, and MongoDB.</strong>
        </p>
      </div>
    </Container>
  );
};

const Container = styled.section`
  background-color: #ffe6e0;
  padding: 70px 24px;

  h2 {
    font-size: 18px;
    font-family: "Monteserrat";
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .flexbox svg {
visibility: hidden;
  }

  img {
    width: 100%;
    object-fit: cover;
    /* border-radius: 0.875rem; */
  }

  header {
    padding: 20px;
    .box {
      /* width: 80%; */
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      a {
        text-decoration: none;
      }
    }
    .box + .box {
      margin-top: 20px;
    }

    .description {
      /* height: 120px; */
      margin-top: -10px;
      background-color: #5f4b4a;
      color: #fff;
      border: 1px solid #000;
      /* text-align: center; */
      font-size: 14px;
      line-height: 24px;
      padding: 20px;

      ul {
        display: flex;
        /* justify-content: center; */
        align-items: center;
        flex-wrap: wrap;
        margin-top: 10px;
        li {
          margin-left: 10px;
          list-style: none;
          font-weight: 600;
        }
      }
    }
  }

  .details {
    margin-top: 20px;

    h3 {
      margin-bottom: 10px;
      font-family: "medium";
      font-size: 45px;
      line-height: 50px;
    }
    p {
      font-size: 14px;
      line-height: 25px;
    }
  }

  @media (min-width: 850px) {
    padding: 80px 70px;

    .flexbox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      h2 {
        margin-left: 3vw;
        font-size: 18px;
        font-family: "Montserrat";
        font-weight: 400;
      }
      svg {
        visibility: visible;
        font-size: 28px;
        font-weight: 600;
        margin-bottom: 10px;
      }
    }

    header {
      display: flex;
      align-items: center;
      gap: 20px;
      overflow-x: scroll !important;
      &::-webkit-scrollbar {
        display: none;
      }

      .box {
        min-width: 350px;
        display: block;

        p {
          font-size: 14px;
        }
      }
      .box + .box {
        margin-top: 0px;
      }
    }

    .details {
      margin-top: 40px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      h3 {
        max-width: 380px;
        line-height: 60px;
        margin-bottom: 10px;
        font-family: "medium";
        font-size: 50px;
      }
      p {
        font-size: 16px;
        max-width: 450px;
        line-height: 30px;
      }
    }
  }
`;

export default Projects;
