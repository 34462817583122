import Standage from "../../assets/standage.gif";
import Digitrad from "../../assets/digitradpc.gif";
import Autos from "../../assets/autos.gif";
import Cryptopunks from "../../assets/cryptopunks.png";
import Google from "../../assets/google.gif";
import Coffee from "../../assets/coffee.gif";
import ArtShop from "../../assets/artshop.gif";
import ChillTV from "../../assets/chilltv.gif";

const data = [
  {
    id: 1,
    title: "Kenad and Standage",
    img: Standage,
    website: "https://standage.com.ng/",
    repo: "",
    tools:
      "<li>React.js</li><li>Typescript</li><li>Styled Components</li><li>Wordpress API</li><li>Nodemailer</li>",
    content:
      "<p>Developed a fully functional website for Kenad and Standage Limited - an IT Solutions & International Trading platdform</p><p>it has features such as merchandize showcase and wordpress blog plugin</p>",
  },

  {
    id: 2,
    title: "Digitrad PC",
    img: Digitrad,
    website: "https://digitradpc.com.ng/",
    repo: "",
    tools: "<li>Shopify Themes</li><li>Flutterwave Payment Integration</li>",
    content:
      "<p>An E-commerce application that features  high quality pre-owned laptops ranging from Hp, Dell, and MacBooks at a pocket friendly price</p><p>it has features such as Cart system, Shipping form, Payment integration</p>",
  },

  {
    id: 3,
    title: "Standage Automobiles",
    img: Autos,
    website: "https://www.standageautos.com.ng/",
    repo: "",
    tools: "<li>React.js</li><li>Nodemailer</li><li>Styled components</li>",
    content:
      "<p>Created a landing page that features various  pre-owned vehicles from Standage Autos</p>",
  },

  {
    id: 4,
    title: "Art Shop",
    img: ArtShop,
    website: "https://my-art-shop.vercel.app/",
    repo:"https://github.com/mzoyinda/ArtShop",
    content:
      "<p>A website that helps users shop for beautiful wall posters, for their home or workspace.</p>",
    tools:
      "<li>Next.js</li><li>Styled Components</li><li>REST API</li>",
  },

  {
    id: 5,
    title: "Coffe Tip",
    img: Coffee,
    website: "https://coffeetip.vercel.app/",
    repo:"https://github.com/mzoyinda/Chimoney-API-DevChallenge/tree/main/submissions/coffee-tip",
    content:
      "<p>A platform that helps creators collect tips using chimoney payment API</p>",
    tools:
      "<li>React.js</li><li>Node Js</li><li>Express JS</li><li>Mongo DB</li><li> Chimoney API and Wallet integration</li>",
  },

  {
    id: 6,
    title: "Chill TV",
    img: ChillTV,
    website: "https://chilltv.vercel.app/",
    repo: "https://github.com/mzoyinda/favorite-movie",
    content:
      "<p>A website that helps user store their favorite movies. You can Create, Delete and Search for a movie.</p>",
    tools:
      "<li>React.js</li><li>Node Js</li><li>Express JS</li><li>Mongo DB</li><li> IMDB movies API</li>",
  },


  {
    id: 7,
    title: "CryptoPunk",
    img: Cryptopunks,
    website: "https://custom-cryptopunks.netlify.app/",
    repo: "https://github.com/mzoyinda/cryptopunk",
    tools:
      "<li>React.js</li><li>React Hooks</li><li>Axios</li><li>Web 3.0</li><li>Node Js</li>",
    content:
      "<p>CryptoPunk is a Modern Cryptopunk Clone that was created with Web3 stack and NFTs published on OpenSea (a marketplace for NFTs) which uses a cryptocurrency wallet (MetaMask) to interact with the Ethereum blockchain.</p>",
  },

  {
    id: 8,
    title: "Google Clone",
    img: Google,
    website: "https://google-dev-lite.netlify.app/",
    repo: "https://github.com/mzoyinda/google",
    tools: "<li>HTML</li><li>CSS</li><li>JavaScript</li>",
    content: "<p>A basic clone of Google search engine </p>",
  },
];

export default data;
