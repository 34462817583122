import React from "react";
import styled from "styled-components";
import BG from "../../assets/contact-bg.jpeg";
import {
  FaSquareInstagram,
  FaLinkedinIn,
  FaTwitter,
  FaGithub,
  FaHashnode,
} from "react-icons/fa6";
const Contact = () => {
  return (
    <Container>
      <h3>Work with me</h3>
      <div className="footer">
        <img src={BG} alt="wallpaper" />
        <div className="column">
          <div className="socials">
            <h4>Socials</h4>
            <div className="row">
              <a href="https://www.linkedin.com/in/oyin-dawodu" target="_blank" rel="noopener noreferrer">
              <FaLinkedinIn className="in" />
              </a>
              <a href="https://twitter.com/oyindawodu" target="_blank" rel="noopener noreferrer">
              <FaTwitter className="x" />
              </a>
              <a href="https://oyindawodu.hashnode.dev/" target="_blank" rel="noopener noreferrer">
              <FaHashnode className="hash" />
              </a>
              <a href="https://www.instagram.com/oyin_dawodu/" target="_blank" rel="noopener noreferrer">
              <FaSquareInstagram className="ig" />
              </a>
              <a href="https://github.com/mzoyinda" target="_blank" rel="noopener noreferrer">
              <FaGithub className="git" />
              </a>
            </div>
          </div>
          <div className="email">
            <h4>Email</h4>
            <p>oyindamoladawodu22@gmail.com</p>
          </div>
        </div>
      </div>
    </Container>
  );
};

const Container = styled.section`
  background-color: #473433;
  color: white;
  padding: 24px;
  padding-top: 40px;
  font-family: 'Montserrat';

  h3 {
    font-size: 28px;
    font-family: medium;
    margin-bottom: 40px;
  }

  img {
    width: 100%;
    max-width: 450px;
    height: 250px;
    object-fit: cover;
  }

  .row {

    a{
      text-decoration: none;
    }

    a + a {
      margin-left: 14px;
    }

    svg {
      font-size: 30px;
      border-radius: 20%;
      color: white;

      &:hover{
        font-size: 28px;
      }
    }

    svg.hash {
      color: #2b60f6;
    }

    svg.ig {
      color: #c9387d;
      background-color: white;
      border-radius: 50%;
      padding: 3px;
    }

    svg.x {
      color: white;
      font-size: 28px;
      padding: 5px;
      background-color: #369df0;
    }

    svg.in {
      color: white;
      font-size: 28px;
      padding: 5px;
      background-color: #369df0;
    }
  }

  h4{
    text-transform: uppercase;
    font-size: 14px;
    margin-bottom: 10px;
  }

  p{
    font-size: 14px;
  }

  .socials{
    margin-top: 24px;
  }

  .email{
    margin-top: 60px;
    margin-bottom: 40px;
  }

  @media (min-width: 800px){
    padding-top: 100px;
    padding-bottom: 100px;

    h3{
        padding-left: 14vw;
        font-size: 35px;
    }
    h4,p{
        font-size: 16px;
    }
    .footer{
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }

    img{
        max-width: 450px;
    }
  }
`;

export default Contact;
