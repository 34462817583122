import React from "react";
import styled from "styled-components";
import brand from "../assets/brand.png";
import { useNavigate } from "react-router-dom";
import { FaCodeCompare } from "react-icons/fa6";
import { HashLink } from "react-router-hash-link";

const Navbar = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    window.scrollTo(0, 0);
    navigate("/projects");
  };

  return (
    <Container>
      <div className="desktop">
        <a href="/">
          <img src={brand} alt="standage" />{" "}
        </a>

        <nav className="nav__links">
          <HashLink smooth to="/#about">
            About
          </HashLink>
          <HashLink smooth to="/#blog">
            Blog
          </HashLink>
          <HashLink smooth to="/#events">
            Events
          </HashLink>
        </nav>

        <button onClick={handleClick}>
          <FaCodeCompare />
          Projects
        </button>
      </div>
    </Container>
  );
};

const Container = styled.div`
  max-width: 1700px;
  background: #ffffff;
  box-shadow: 0px 5px 15px rgba(153, 153, 153, 0.05);
  padding: 17px 24px;
  padding-bottom: 0;
  /* height: 66px; */
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;

  a {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    text-decoration: none;
    color: #808080;
    :hover {
      font-weight: 600;
      cursor: pointer;
      color: #4d4d4d;
    }
  }

  img {
    width: 100px;
    object-fit: cover;
  }
  .nav__links {
    display: none;
  }

  button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* padding: 5px 10px; */
    width: 125px;
    height: 40px;
    font-weight: 600;
    font-size: 10px;
    border-radius: 10px;
    border: transparent;
    line-height: 16px;
    color: #ffffff;
    background: #0f162c;
    svg {
      margin-right: 8px;
    }
    :hover {
      cursor: pointer;
      background: var(--lightBrown);
    }
  }

  /* large screen */
  .desktop {
    display: flex;
    box-shadow: 0px 5px 15px rgba(153, 153, 153, 0.05);
    justify-content: space-between;
    align-items: center;
    /* height: 66px; */
    a + a {
      margin-left: 42px;
    }
    img {
      width: 150px;
      object-fit: cover;
    }
    .nav__links {
      display: none;
    }
  }

  //maximum screen size
  @media (min-width: 1700px) {
    display: flex;
    align-items: center;
    justify-content: center;

    .desktop {
      width: 1700px;
      justify-content: space-between;
      align-items: center;
    }
  }

  @media (min-width: 768px) {
    padding: 12px 73px;
    padding-bottom: 3px;
    .nav__links {
      display: block !important;
    }
    .desktop {
      justify-content: space-between;
      align-items: center;
    }
    button {
      width: 131px;
      height: 48px;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
    }
  }
`;

export default Navbar;
