import React from "react";
import Herosection from "./Herosection";
import styled from "styled-components";
import About from "./About";
import Footer from "../../components/Footer/Footer";
import Contact from "../../components/Footer/Contact";
import Skills from "./Skills";
import Quote from "./Quote";
import Projects from "./Projects";
import Blog from "./Blog";
import Events from "./Events";
import Navbar from "../../components/Navbar";

const Portfolio = () => {
  return (
      <>
        <Herosection />
        <About />
        <Skills />
        <Projects />
        <Blog />
        <Events/>
        <Quote />
      </>
  );
};

export default Portfolio;
