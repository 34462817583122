import React from "react";
import styled from "styled-components";
import BlogPC from "../../assets/blog.png";

const Blog = () => {
  return (
    <Container id="blog">
      <div className="box">
        <h3>My Blog</h3>
        <p>
          I write, edit, and maintain clear, concise, and accurate technical
          documentation, including conceptual deep dives, guides, tutorials, and
          API references.
        </p>
        <a href="https://oyindawodu.hashnode.dev/">Visit My Blog</a>
      </div>
      <div className="img__box">
        <img src={BlogPC} alt="blog samples" />
      </div>
    </Container>
  );
};

const Container = styled.section`
  padding: 70px 24px;

  .box {
    margin-bottom: 40px;
    h3 {
      margin-bottom: 10px;
      font-family: medium;
      font-size: 45px;
      line-height: 50px;
    }
    p {
      font-size: 14px;
      line-height: 25px;
      max-width: 480px;
      margin-bottom: 40px;
    }

    a {
      padding: 20px 30px;
      text-decoration: none;
      margin-top: 20px;
      background-color: #473433;
      color: #faefe6;
      font-size: 16px;
      font-weight: 700;
      text-transform: uppercase;
      border: transparent;

      &:hover {
        cursor: pointer;
        background: transparent;
        color: #473433;
        border: 1px solid #473433;
      }
    }
  }

  .img__box {
    display: flex;
    justify-content: center;
    img {
      width: 100%;
      max-width: 400px;
      object-fit: cover;
    }
  }

  @media (min-width: 500px) {
    padding: 80px 60px;
  }

  @media (min-width: 768px) {
    padding: 100px 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    
    .box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h3 {
        /* max-width: 380px; */
        line-height: 60px;
        margin-bottom: 20px;
        font-family: medium;
        font-size: 50px;
      }

      p {
        font-size: 16px;
        margin-bottom:0;
        max-width: 360px;
        text-align: center;
        line-height: 30px;
      }
      button {
        margin-top: 40px;
      }
    }
  }
`;

export default Blog;
