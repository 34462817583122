import React from "react";
import styled from "styled-components";

const Header = () => {
  return (
    <Container>
        {/* <h2>HI THERE 👋 </h2> */}
      <h2>Welcome to my Project Hub! 👋 </h2>
      {/* <p>
        I'm Oyinda, a Software Developer with industry experience in
        constructing websites and web applications.
      </p> */}
      <p>
        Here, I compile projects I've primarily worked on using,<strong> JavaScript,
        TypeScript,</strong> and frameworks like <strong>React.js, Next.js, Styled Components,
        Node.js, Express, and MongoDB</strong> for seamless data integration.
      </p>
    </Container>
  );
};

const Container = styled.header`
padding: 24px;
margin-bottom: 5rem;
padding-top: 80px;
max-width: 500px;
margin-left: 4rem;

h2{
    font-weight: 600;
    font-size: 2rem;
    font-family: cursive-medium;
    margin-bottom: 16px;
}
p+p{
    margin-top: 10px;
}
p{
    font-size: 16px;
    font-family: Montserrat;
    line-height: 40px;
    margin-left: 5px;
}
`;

export default Header;
