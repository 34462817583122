import React from "react";
import { Outlet} from "react-router-dom";
import Footer from "./Footer/Footer";
import Navbar from "./Navbar";
import styled from "styled-components";

const Wrapper = () => {
  
  return (
    <Wrap>
      <HomeContainer>
        <Navbar />
        <Outlet />
        <Footer />
      </HomeContainer>
    </Wrap>
  );
};

const HomeContainer = styled.main`
  max-width: 1700px;
  position: relative;
`;

const Wrap = styled.div`
  @media (min-width: 1700px) {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
`;

export default Wrapper;
